































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { BButton, BSpinner } from 'bootstrap-vue'
import leftoversModule from '@/store/leftovers'
import { Maybe } from 'graphql/jsutils/Maybe'
import { settingsModule } from '@/store/settings'
import { cart } from '@/store/cart/cartModule'
import terminalModule from '@/store/terminal'

@Component({
  components: { BButton, BSpinner }
})
export default class Product extends Vue {
  loading = 0

  get img() {
    return this.leftover?.product.img
  }

  get title() {
    return this.leftover?.product.title
  }

  get weight() {
    return this.leftover?.product.weight
  }

  get ingredients() {
    return this.leftover?.product.ingredients
  }

  get calories() {
    return this.leftover?.product.calories
  }

  get proteins() {
    return this.leftover?.product.proteins
  }

  get fats() {
    return this.leftover?.product.fats
  }

  get carbohydrates() {
    return this.leftover?.product.carbohydrates
  }

  get netPrice() {
    return this.leftover?.product.netPrice
  }

  get salePrice() {
    return this.leftover?.product.salePrice
  }

  get price() {
    return this.leftover?.price
  }

  get isInStock() {
    return this.leftover?.currentAmount && this.leftover.currentAmount > 0
  }

  get isShowAddToBasket() {
    return !terminalModule.getters.terminal?.isDemoMode
  }

  get hostOfImages(): string {
    return settingsModule.getters.mediaPath || ''
  }

  @Prop({ type: String, required: true }) id!:string

  get leftover() {
    return leftoversModule.mapLeftovers
      ?.find(edge => edge?.product.id === this.id)
  }

  mounted():void {
    window.scrollTo({ top: 0 })
  }

  async addToCard(): Promise<void> {
    if (!this.isShowAddToBasket) return

    if (this.leftover?.product.id) {
      this.loading += 1
      try {
        cart.addItem({ productId: this.leftover.product.id, count: 1 })
      } finally {
        this.loading -= 1
      }
    }
  }

  thereIsField(value: Maybe<number>) {
    return value || value === 0
  }
}
